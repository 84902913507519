import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//获取员工信息
export const memberDetails = (id: any): Promise<any> =>
  request.get("/api/member/" + id);

//新增员工
export const memberAdd = (data: any): Promise<any> =>
  request.post("/api/member", data);

//修改员工
export const memberUpdate = (id: string, data: any): Promise<any> =>
  request.put("/api/member/" + id, data);

//员工列表
export const memberList = (): Promise<any> => request.get("/api/member");

//员工删除
export const memberDelete = (id: any): Promise<any> =>
  request.delete("/api/member/" + id);

//员工列表通用接口
export const memberQuery = (params: any): Promise<any> => {
  const params_ = Object.assign({}, params, { select: "*" });
  if (params_.depart) {
    params_.department_id = "eq." + params_.depart;
  }
  if (params_.groupId) {
    params_.group_id = "eq." + params_.groupId;
  }
  if (params_.key) {
    params_.or = `name.like.${params_.key},title.like.${params_.key},work_number.like.${params_.key}`; //eq等于，like模糊
  }
  delete params_.key;
  if (params_.userStatus) {
    params_.resign_time = params_.userStatus;
  }
  if (params_.status) {
    params_.key = params_.status;
  }

  delete params_.depart;
  delete params_.groupId;
  delete params_.status;
  delete params_.userStatus;
  return request.get(
    `/api/member/${params_.key ? "filter_by_salary_status" : "query"}`,
    { params: params_ }
  );
};

//员工列表通用接口
export const memberStaQuery = (params: any): Promise<any> => {
  const params_ = Object.assign({}, params, { select: "*" });
  let type = "1";
  const year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  const time_ = year + "-" + month + "-01 00:00:00";
  if (params_.staActive) {
    if (params_.staActive == "onboardNum") {
      params_.resign_time = "isNull";
      params_.group_id = "eq." + params_.groupId;
    } else if (params_.staActive == "initSalaryNum") {
      params_.key = "INIT";
      type = "2";
    } else if (params_.staActive == "notInitSalaryNum") {
      params_.key = "NOT_INIT";
      type = "2";
    } else if (params_.staActive == "additions") {
      params_.onboard_time = "ge.time." + time_;
      params_.group_id = "eq." + params_.groupId;
    } else if (params_.staActive == "departures") {
      params_.resign_time = "ge.time." + time_;
      params_.group_id = "eq." + params_.groupId;
    } else if (params_.staActive == "monthInitSalaryNum") {
      params_.key = "INIT";
      params_.after = time_;
      params_.group_id = "eq." + params_.groupId;
      type = "2";
    } else if (params_.staActive == "notGroupedNum") {
      params_.group_id = "isNull";
    }
  }
  delete params_.staActive;
  delete params_.groupId;
  return request.get(
    `/api/member/${type == "2" ? "filter_by_salary_status" : "query"}`,
    { params: params_ }
  );
};

//员工定薪
export const initSalary = (id: string, data: any): Promise<any> =>
  request.post("/api/member/" + id + "/initSalary", data);

//员工调薪
export const changeSalary = (id: string, data: any): Promise<any> =>
  request.put("/api/member/" + id + "/changeSalary", data);

//获取员工基本薪酬信息
export const salaryDetails = (id: any): Promise<any> =>
  request.get("/api/member/" + id + "/salary");

//获取员工调薪记录
export const historySalary = (id: any, params: any): Promise<any> =>
  request.get("/api/member/" + id + "/historySalary", { params });

//同步员工
export const synchronizationMemberinfo = (): Promise<any> =>
  request.get("/api/member/synchronizationMemberinfo");

//统计员工
export const statistic = (): Promise<any> =>
  request.get("/api/member/statistic");

//获取批量定薪模板
export const initSalaryBatch = (query: any): Promise<any> =>
  request.get("/api/member/initSalaryBatch", { params: query });

//获取批量调薪模板
export const changeSalaryBatch = (query: any): Promise<any> =>
  request.get("/api/member/changeSalaryBatch", { params: query });

//批量定薪
export const initSalaryPutBatch = (query: any, data: any): Promise<any> =>
  request.put("/api/member/initSalaryBatch", data, { params: query });

//批量调薪
export const changeSalaryPutBatch = (query: any, data: any): Promise<any> =>
  request.put("/api/member/changeSalaryBatch", data, { params: query });

//新增员工
export const extraUpdate = (id: any, data: any): Promise<any> =>
  request.put("/api/member/" + id + "/extra", data);
