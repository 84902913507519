<template>
  <div class="common_edit_from" id="common_edit_from">
    <my-dialog
      v-show="!showDetails"
      modal-append-to="common_edit_from"
      @closeFn="closeFn"
      :height="50"
      :width="20"
      :title="`批量${type == 'init' ? '定' : '调'}薪页面`"
    >
      <template>
        <div class="my_dialog_body-content">
          <el-form
            ref="editFormInfoForm"
            :model="editFormInfo"
            :rules="editFormInfoRules"
            :inline-message="true"
            label-width="5.5vw"
            class="infoForm"
            label-position="left"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="选择用户组" prop="groupId">
                  <el-select
                    clearable
                    filterable
                    v-model="editFormInfo.groupId"
                    placeholder="请选择用户分组"
                    :disabled="!isEditFlag"
                  >
                    <el-option
                      v-for="(item, index) in groupTypeList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="上传excel">
                  <div class="batch_excel" @click="exportTem">下载模板</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label>
                  <label class="file-select">
                    <div class="salary_upload">
                      <i class="el-icon-plus"></i>
                      <input
                        type="file"
                        ref="fileUploadForm"
                        @change="handleFileChange"
                      />
                    </div>
                  </label>
                  <div class="file_name">{{ filename }}</div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="my_dialog_body-foot">
          <div class="my_dialog_body-btn" @click="saveFn">确定</div>
          <div class="my_dialog_body-btn" @click="closeFn()">取消</div>
        </div>
      </template>
    </my-dialog>
    <batchDetailsForm
      v-if="showDetails"
      :info="resList"
      :type="type"
      @closeFn="closeBatchFn"
    ></batchDetailsForm>
  </div>
</template>
<script>
import { groupList } from "@/api/user/group";
import authModule from "@/store/modules/auth";
import userModule from "@/store/modules/user";
import {
  initSalaryBatch,
  changeSalaryBatch,
  initSalaryPutBatch,
  changeSalaryPutBatch,
} from "@/api/salary/member.ts";
import batchDetailsForm from "./batchDetailsForm.vue";
export default {
  props: {
    type: {
      type: String,
      default: "init",
    },
  },
  components: { batchDetailsForm },
  data() {
    return {
      editFormInfo: {
        groupId: "",
      },
      editFormInfoRules: {
        groupId: [{ required: true, message: "请选择用户组" }],
      },
      isEditFlag: true,
      groupTypeList: [],
      formData: null,
      filename: "",
      resList: {
        failCount: 0,
        invalidRowCount: 0,
        successCount: 0,
        failRecords: [],
      },
      showDetails: false,
    };
  },
  mounted() {
    this.setGroupId();
    groupList({}).then((res) => {
      this.groupTypeList = res.content.content.records || [];
    });
  },
  methods: {
    setGroupId() {
      const auths = authModule.auths;
      const auths_ = "ROLE::ADMIN,ROLE::PAYROLL_ADMIN";
      if (auths.filter((c) => auths_.indexOf(c) != -1).length == 0) {
        let userInfo = userModule.userInfo;
        this.editFormInfo.groupId = userInfo.groupId || "";
        this.isEditFlag = false;
      }
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
    saveFn() {
      let flag = true;
      this.$refs.editFormInfoForm.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }
      if (this.formData == null) {
        this.$message.warning("请选择上传文件");
        return;
      }
      (this.type == "init" ? initSalaryPutBatch : changeSalaryPutBatch)(
        { groupId: this.editFormInfo.groupId },
        this.formData
      ).then((res) => {
        this.resList = res.content;
        this.showDetails = true;
      });
    },
    exportTem() {
      let flag = true;
      this.$refs.editFormInfoForm.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }
      if (this.type == "init") {
        initSalaryBatch({ groupId: this.editFormInfo.groupId }).then((res) => {
          debugger;
          this.saveAs(res, "批量定薪模板");
        });
      } else {
        changeSalaryBatch({ groupId: this.editFormInfo.groupId }).then(
          (res) => {
            debugger;
            this.saveAs(res, "批量调薪模板");
          }
        );
      }
    },
    saveAs(data, name) {
      const blob = new Blob([data], { type: "application/vnd.ms-excel" }); //创建一个新的 Blob 对象
      const URL = window.URL || window.webkitURL;
      const url = URL.createObjectURL(blob); //  把一个blob对象转化为一个Blob URL，创建下载链接
      const downloadLink = document.createElement("a"); // 生成一个a标签
      downloadLink.href = url;
      downloadLink.download = name + ".xlsx"; // // dowload属性指定下载后文件名
      document.body.appendChild(downloadLink); //将a标签添加到body中
      downloadLink.click(); // 点击下载
      document.body.removeChild(downloadLink); // 下载完成后移除元素
      window.URL.revokeObjectURL(url); // 释放掉blob对象
    },

    //上传按钮事件
    handleFileChange(file, fileLists) {
      //本地程序需解析文件获取本地文件绝对路径
      this.beforeUpload(this.$refs.fileUploadForm.files);
      this.$refs.fileUploadForm.value = "";
    },
    beforeUpload(files) {
      let exits = ["xlsx", "xls"];

      for (let i = 0; i < files.length; i++) {
        if (
          exits.findIndex(
            (c) =>
              c ==
              files[i].name
                .substring(files[i].name.lastIndexOf(".") + 1)
                .toLowerCase()
          ) != -1
        ) {
          this.formData = new FormData();
          this.formData.append("file", files[i]);
          console.log(this.formData);
          this.filename = files[i].name;
        } else {
          this.$message.warning(
            "暂不支持" +
              files[i].name.substring(files[i].name.lastIndexOf(".") + 1) +
              "格式文件上传"
          );
        }
      }
    },
    closeBatchFn() {
      this.$emit("closeFn", true);
    },
  },
};
</script>
<style scoped lang="less">
.batch_excel {
  color: #00759c;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
.salary_upload {
  width: 15vh;
  height: 15vh;
  line-height: 15vh;
  text-align: center;
  border: 1px solid #222222;
  cursor: pointer;
  input[type="file"] {
    display: none;
  }
  i {
    color: #222222;
  }
}
</style>
