








































































































































































































































import Vue from "vue";
import {
  memberAdd,
  memberUpdate,
  memberDetails,
  extraUpdate,
} from "@/api/salary/member";
import { memberExtra } from "@/api/salary/employee";
import { Des_encrypt } from "@/utils/pubkey";
import { groupList } from "@/api/user/group";
import { genderList } from "@/api/system/dict";
import { validatorPhone } from "@/utils/validate";
import { departmentList } from "@/api/user/department";

export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo", "isEditType"],
  data() {
    return {
      tabsList: [
        { name: "基本信息", value: "base" },
        { name: "额外信息", value: "extra" },
      ],
      tabActive: "",
      formatType: "yyyy-MM-dd HH:mm:ss",
      editFormInfo: {
        name: null, //姓名
        phone: null, //电话
        workNumber: null, //工号
        gender: null, //性别
        onboardTime: null, //入职时间
        groupId: null, //员工分组
        departmentId: null, // 员工部门
        title: null, //职位
        payrollCard: null, //工资卡
        cardBank: null, //开户行
        payrollCenter: null, //费用归属
        resignTime: null, //离职时间
      },
      editFormInfoRules: {
        name: [{ required: true, message: "请输入员工姓名" }],
        phone: [
          {
            validator: validatorPhone,
          },
        ],
      },
      extraFormInfos: [],
      //字典
      genderTypeList: [],
      departmentList: [],
      groupTypeList: [],
      expandedList: [],
      filterText: "",
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  watch: {
    filterText(val) {
      this.$refs.treeForm.filter(val);
    },
  },
  mounted: async function () {
    await memberExtra({ groupId: this.baseEditInfo.groupId }).then((res) => {
      res.content.records.forEach((c) => {
        this.extraFormInfos.push({
          id: null,
          extraDefineId: c.id,
          value: c.defaultValue,
          name: c.name,
        });
      });
    });
    this.tabClick(this.tabsList[0]);
    this.genderTypeList = genderList;
    await departmentList().then((res) => {
      this.departmentList = res.content || [];
    });
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });
    if (this.baseEditInfo.id) {
      await memberDetails(this.baseEditInfo.id).then((res: any) => {
        this.editFormInfo = this.$deepCopy(res.content);
        if (
          this.editFormInfo.roles &&
          this.editFormInfo.roles instanceof Array
        ) {
          this.editFormInfo.roles = this.editFormInfo.roles.map(
            (c: any): void => {
              return c.role;
            }
          );
          this.editFormInfo.permissions = this.editFormInfo.permissions.map(
            (c: any): void => {
              return c.permission;
            }
          );
        }

        if (
          this.editFormInfo.extraInfos &&
          this.editFormInfo.extraInfos.length > 0
        ) {
          this.editFormInfo.extraInfos.forEach((c) => {
            if (
              this.extraFormInfos.findIndex(
                (d) => d.extraDefineId == c.extraDefineId
              ) != -1
            ) {
              this.extraFormInfos.find(
                (d) => d.extraDefineId == c.extraDefineId
              ).id = c.id;
              this.extraFormInfos.find(
                (d) => d.extraDefineId == c.extraDefineId
              ).value = c.value;
            }
          });
        }
      });
    }
    this.$nextTick(() => {
      if (this.baseEditInfo.departmentId) {
        this.getDefaultExtent(
          this.departmentList,
          this.baseEditInfo.departmentId
        );
      }
    });
  },
  methods: {
    tabClick(row) {
      if (this.tabActive != row.value) {
        this.tabActive = row.value;
      }
    },
    getDefaultExtent(data, id) {
      if (!data) return; // return; 中断执行
      for (let i in data) {
        let item = data[i];
        if (this.getObjByTree(item.children, id)) {
          this.expandedList.push(item.id);
          if (item.children && typeof item.children == "object") {
            this.getDefaultExtent(item.children, id);
          }
          break;
        }
      }
    },
    getObjByTree(data, id) {
      let result = null;
      if (!data) return; // return; 中断执行
      for (let i in data) {
        if (result !== null) break;
        let item = data[i];
        if (item.id === id) {
          result = item;
          break;
        } else if (item.children && typeof item.children == "object") {
          result = this.getObjByTree(item.children, id);
        }
      }
      return result;
    },
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      if (obj.id) {
        memberUpdate(obj.id, obj).then(() => {
          let extra_ = this.$deepCopy(this.extraFormInfos);
          extraUpdate(obj.id, extra_).then(() => {
            this.$message.success("保存成功");
            this.closeFn(true);
          });
        });
      } else {
        memberAdd(obj).then(() => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      }
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    treeCheck: function (node, list) {
      if (list.checkedKeys.length == 2) {
        this.$refs.treeForm.setCheckedKeys([node.id]);
      }
      this.editFormInfo.departmentId = node.id;
    },
  },
});
