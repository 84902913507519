<template>
  <div class="common_edit_from" id="batch_details_from">
    <my-dialog
      modal-append-to="batch_details_from"
      @closeFn="closeFn"
      :height="errVisible ? 50 : 15"
      :width="40"
      :title="''"
      :headerShow="false"
    >
      <div class="infoShow">
        批量{{ type == "init" ? "定" : "调" }}薪完成,成功{{
          info.successCount
        }}条,数据无效{{ info.invalidRowCount }}条,失败{{ info.failCount }}条,
      </div>
      <div
        class="infoShow"
        style="cursor: pointer; margin-bottom: 1vh"
        @click="errShow"
      >
        失败详细信息
        <i class="el-icon-bottom"></i>
      </div>
      <div
        class="common_panel"
        style="height: 35vh; padding: unset"
        v-if="errVisible"
      >
        <div class="common_body">
          <div class="common_body_list">
            <div class="common_body_table">
              <el-table
                :data="tableData"
                style="width: 100%"
                height="100%"
                cell-class-name="centerCell"
                header-cell-class-name="centerHeaderCell"
              >
                <el-table-column label="序号" type="index" width="70">
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="name"
                  label="姓名"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="message"
                  label="失败原因"
                  show-overflow-tooltip
                ></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="my_dialog_body-foot"
        style="position: absolute; bottom: 0; left: 50%"
      >
        <div class="my_dialog_body-btn" @click="saveFn">确定</div>
      </div>
    </my-dialog>
  </div>
</template>
<script>
export default {
  props: ["info", "type"],
  data() {
    return {
      errVisible: false,
    };
  },
  computed: {
    tableData() {
      if (this.info.failRecords) {
        let list_ = [];
        let row_ = Object.keys(this.info.failRecords);
        row_.forEach((name) => {
          list_.push({
            name: name,
            message: this.info.failRecords[name].message,
          });
        });
        return list_;
      } else {
        return [];
      }
    },
  },
  methods: {
    closeFn() {
      this.$emit("closeFn");
    },
    saveFn() {
      this.$emit("closeFn");
    },
    errShow() {
      this.errVisible = !this.errVisible;
    },
  },
};
</script>
<style scoped>
.infoShow {
  text-align: center;
}
</style>
