






















































































import Vue from "vue";
import { initSalary, changeSalary, salaryDetails } from "@/api/salary/member";
import { positiveNumber } from "@/utils/validate";
import { structure } from "@/api/salary/structure";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo", "isEditType"],
  data() {
    return {
      formatType: "yyyy-MM-dd HH:mm:ss",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      editFormInfo: {
        id: null,
        description: null, ///调薪说明
        effectiveTime: null, //生效时间
        afterData: [],
      },

      editFormInfoRules: {
        effectiveTime: [{ required: true, message: "请输入生效时间" }],
      },
      milaflag: false,
      //字典
    };
  },
  mounted: async function () {
    this.editFormInfo.id = this.baseEditInfo.id;
    await structure({ groupId: this.baseEditInfo.groupId }).then((res) => {
      res.content.records.forEach((c) => {
        this.editFormInfo.afterData.push({
          name: c.name,
          value: c.defaultValue,
        });
      });
    });
    if (this.baseEditInfo.id) {
      await salaryDetails(this.baseEditInfo.id).then((res: any) => {
        if (res.content) {
          res.content.forEach((c) => {
            if (
              this.editFormInfo.afterData.findIndex(
                (d) => d.name == c.baseDefine.name
              ) != -1
            ) {
              this.editFormInfo.afterData.find(
                (d) => d.name == c.baseDefine.name
              ).value = c.value;
            }
            // this.editFormInfo.afterData.push({
            //   name: c.baseDefine.name,
            //   value: c.value,
            // });
          });
        }
      });
    }
  },
  methods: {
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      delete obj.id;
      obj.afterData.forEach((c) => (c.value = Number(c.value)));
      let afterData_ = {};
      obj.afterData.forEach((c) => (afterData_[c.name] = c.value));
      obj.afterData = JSON.stringify(afterData_);
      changeSalary(this.editFormInfo.id, obj).then((res: any) => {
        this.$message.success("保存成功");
        this.closeFn(true);
      });
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
    milaflagChange(val) {
      if (val) {
        let date =
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-01" +
          " 00:00:00";
        this.editFormInfo.effectiveTime = this.$showTime(date, true);
      }
    },
  },
});
