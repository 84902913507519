











































































































































































































































































































import Vue from "vue";
import editForm from "./editForm.vue";
import salaryForm from "./salaryForm.vue";
import tSalaryForm from "./tSalaryForm.vue";
import historyForm from "./historyForm.vue";
import batchEditForm from "./batchEditForm.vue";
import {
  memberQuery,
  memberStaQuery,
  memberDelete,
  synchronizationMemberinfo,
  statistic,
} from "@/api/salary/member";
import { salaryStatusList, userStatusList } from "@/api/system/dict";
import { groupList } from "@/api/user/group";
import { departmentList } from "@/api/user/department";
import authModule from "@/store/modules/auth";
import userModule from "@/store/modules/user";
export default Vue.extend({
  components: {
    editForm,
    salaryForm,
    tSalaryForm,
    historyForm,
    batchEditForm,
  },
  data() {
    return {
      staList: [
        { name: "在职人数", key: "onboardNum", value: 0, show: true },
        { name: "已定薪人数", key: "initSalaryNum", value: 0, show: true },
        { name: "未定薪人数", key: "notInitSalaryNum", value: 0, show: true },
        { name: "本月新增", key: "additions", value: 0, show: true },
        { name: "本月离职", key: "departures", value: 0, show: true },
        {
          name: "本月定薪人数",
          key: "monthInitSalaryNum",
          value: 0,
          show: true,
        },
        { name: "总调薪次数", key: "changeSalaryNum", value: 0 },
        { name: "本月定薪次数", key: "monthChangeSalaryNum", value: 0 },
        { name: "未分组人数", key: "notGroupedNum", value: 0, show: true },
        {
          name: "调薪记录未生效次数",
          key: "notEffectiveSalaryRecordNum",
          value: 0,
        },
      ],
      staActive: "",
      searchObj: {
        key: "",
        depart: null,
        groupId: "",
        status: "",
        userStatus: "",
      },
      pageObj: {
        pageSize: 10,
        pageNum: 1,
      },
      pageSizesList: [10, 15, 20, 50, 100],
      tableTotal: 0,
      tableData: [],

      showGroup: true,

      editFormVisible: false,
      isEditFlag: false,
      isEditType: "",
      baseEditInfo: {},

      salaryStatusList: [],
      groupTypeList: [],
      departmentList: [],
      userStatusList: [],

      //批量
      batchVisible: "",
    };
  },
  watch: {
    "searchObj.status"(val) {
      if (val) {
        this.searchObj.key = "";
        this.searchObj.groupId = "";
        this.searchObj.depart = null;
        this.searchObj.userStatus = "";
        this.staActive = "";
      }
    },
    "searchObj.key"(val) {
      if (val) {
        this.searchObj.status = "";
        this.staActive = "";
      }
    },
    "searchObj.groupId"(val) {
      if (val) {
        this.searchObj.status = "";
        this.staActive = "";
      }
    },
    "searchObj.depart"(val) {
      if (val) {
        this.searchObj.status = "";
        this.staActive = "";
      }
    },
    "searchObj.userStatus"(val) {
      if (val) {
        this.searchObj.status = "";
        this.staActive = "";
      }
    },
  },
  mounted() {
    this.setGroupId();
    this.salaryStatusList = salaryStatusList;
    this.userStatusList = userStatusList;
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });
    departmentList({}).then((res) => {
      this.departmentList = res.content || [];
    });
    this.getList();
  },
  methods: {
    setGroupId() {
      const auths = authModule.auths;
      const auths_ = "ROLE::ADMIN,ROLE::PAYROLL_ADMIN";
      if (auths.filter((c) => auths_.indexOf(c) != -1).length == 0) {
        let userInfo = userModule.userInfo;
        this.searchObj.groupId = userInfo.groupId || "";
        this.showGroup = false;
      }
    },
    //common
    resetFn() {
      this.searchObj = this.$options.data().searchObj;
      this.setGroupId();
      this.timeValue = "";
      this.staActive = "";
      this.searchFn();
    },
    searchFn() {
      this.pageObj = this.$options.data().pageObj;
      this.getList();
    },
    pageNumChange(val: any) {
      this.pageObj.pageNum = val;
      this.getList();
    },
    pageSizeChange(val: any) {
      this.pageObj.pageNum = 1;
      this.pageObj.pageSize = val;
      this.getList();
    },
    getList() {
      this.getListData();
      this.getStaData();
    },
    listEditFn(row, type) {
      this.editFormVisible = true;
      this.isEditFlag = type == "seeSalary" ? false : true;
      this.isEditType = type;
      this.baseEditInfo = row;
    },
    listDeleteFn(row: any) {
      this.$confirm("是否删除本条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        memberDelete(row.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.searchFn();
        });
      });
    },
    closeFn(flag = false) {
      this.editFormVisible = false;
      this.isEditType = "";
      if (flag) {
        this.getList();
      }
    },
    //业务
    getListData() {
      if (!this.staActive) {
        const obj = Object.assign({}, this.searchObj, this.pageObj);
        memberQuery(obj).then((res: any) => {
          this.tableData = res.content?.records || [];
          this.tableTotal = res.content?.total || 0;
        });
      } else {
        const obj = Object.assign(
          {},
          { staActive: this.staActive, groupId: this.searchObj.groupId },
          this.pageObj
        );
        memberStaQuery(obj).then((res: any) => {
          this.tableData = res.content?.records || [];
          this.tableTotal = res.content?.total || 0;
        });
      }
    },
    getStaData() {
      statistic().then((res) => {
        this.staList.forEach((c) => (c.value = res.content[c.key] || 0));
      });
    },
    addFn() {
      console.log("获取新增");
    },
    deleteFn() {
      console.log("删除");
    },
    exportFn() {
      console.log("模板下载");
    },
    importFn() {
      console.log("批量导入");
    },
    batchFn(val) {
      this.batchVisible = val;
    },
    closeBatchFn(flag) {
      this.batchVisible = "";
      if (flag) {
        this.getList();
      }
    },
    synFn() {
      this.$message.warning("正在同步，请稍后...");
      synchronizationMemberinfo().then((res: any) => {
        if (res.status == "SUCCESS") {
          this.$message.success("同步成功");
          this.searchFn();
        } else {
          this.$message.warning("同步失败");
        }
      });
    },
    getDepartment(depart: any, flag = true) {
      let department_ = "";
      if (flag) {
        department_ = this.showDepartment(depart);
        if (department_.length > 0) {
          department_ = department_.substr(0, department_.length - 1);
        }
      } else {
        department_ = depart?.name || "";
      }

      return department_;
    },
    showDepartment(depart: any) {
      let department_ = "";
      if (depart && Object.keys(depart).length > 0) {
        department_ = depart.name + "/" + department_;
        department_ = this.showDepartment(depart.parent) + department_;
      }

      return department_;
    },
    normalizerApp(node: any) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },
    staClick(row) {
      if (this.staActive == row.key) {
        this.staActive = "";
      } else {
        this.staActive = row.key;
        this.searchObj = this.$options.data().searchObj;
        this.setGroupId();
      }
      this.searchFn();
    },
  },
});
