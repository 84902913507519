



























































































































import Vue from "vue";
import salaryForm from "./salaryForm.vue";
import { historySalary } from "@/api/salary/member";
export default Vue.extend({
  components: { salaryForm },
  props: ["baseEditInfo"],
  data() {
    return {
      pageObj: {
        pageSize: 10,
        pageNum: 1,
      },
      pageSizesList: [10, 15, 20, 50, 100],
      tableTotal: 0,
      tableData: [],

      editFormVisible: false,
      isEditFlag: false,
      isEditType: "see",
      editInfo: {},
      afterDataInfo: [],
      beforeDataInfo: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
    listEditFn(row: any, type: string) {
      this.editFormVisible = true;
      this.isEditFlag = type == "see" ? false : true;
      this.isEditType = type;
      this.editInfo = row;
    },
    listDeleteFn() {
      this.$confirm("是否撤销?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // groupDelete(row.id).then((res) => {
        //   this.$message({
        //     type: "success",
        //     message: "删除成功",
        //   });
        //   this.searchFn();
        // });
      });
    },
    closeFormFn() {
      this.editFormVisible = false;
    },
    pageNumChange(val: any) {
      this.pageObj.pageNum = val;
      this.getList();
    },
    pageSizeChange(val: any) {
      this.pageObj.pageNum = 1;
      this.pageObj.pageSize = val;
      this.getList();
    },
    getList() {
      this.getListData();
    },
    //业务
    getListData() {
      const obj = Object.assign({}, this.pageObj);
      historySalary(this.baseEditInfo.id, obj).then((res: any) => {
        this.tableData = res.content?.records || [];
        this.tableData.forEach((c, idx) => {
          c.afterData = JSON.parse(c.afterData);
          c.beforeData = JSON.parse(c.beforeData);
          if (idx == 0) {
            this.afterDataInfo = Object.keys(c.afterData);
            this.beforeDataInfo = Object.keys(c.beforeData);
          }
        });
        this.tableTotal = res.content?.total || 0;
      });
    },
  },
});
