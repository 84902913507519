















































import Vue from "vue";
import { initSalary, changeSalary, salaryDetails } from "@/api/salary/member";
import { structure } from "@/api/salary/structure";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo", "isEditType"],
  data() {
    return {
      editFormInfo: [],
      baseId: "",
      //字典
    };
  },
  mounted: async function () {
    this.baseId = this.baseEditInfo.id;
    await structure({ groupId: this.baseEditInfo.groupId }).then((res) => {
      res.content.records.forEach((c) => {
        this.editFormInfo.push({
          defineId: c.id,
          name: c.name,
          value: c.defaultValue,
        });
      });
    });
    if (this.baseEditInfo.id) {
      await salaryDetails(this.baseEditInfo.id).then((res: any) => {
        if (res.content) {
          res.content.forEach((c) => {
            if (
              this.editFormInfo.findIndex((d) => d.defineId == c.defineId) != -1
            ) {
              this.editFormInfo.find((d) => d.defineId == c.defineId).value =
                c.value;
            }
          });
        }
      });
    }
  },
  methods: {
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      obj.forEach((c) => (c.value = Number(c.value)));
      initSalary(this.baseId, obj).then(() => {
        this.$message.success("保存成功");
        this.closeFn(true);
      });
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
